import axios from 'axios'
import appSlice from '../slice/app.slice'
import { AppDispatch } from '../index'

import { config } from '../../config'

const isValidEmail = (e: string) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(e.toLowerCase())
}

const isValidUserId = (u: string) =>
  u === '{userId}' || u === '' || u === '{userid}' ? false : true

export const getAppList =
  (accountid: string, email: string, userid: string, loading = true) =>
  (dispatch: AppDispatch) => {
    loading && dispatch(appSlice.actions.setLoading(true))
    return axios
      .get(
        `${config.apiUrl}/public/workflow-clients/apps-offered?email=${
          isValidEmail(email) ? `${email}` : ''
        }&userId=${isValidUserId(userid) ? `${userid}` : ''}`,
        {
          headers: {
            accountid: accountid,
          },
        }
      )
      .then((res: any) => {
        let list: any = []
        res?.data.map((item: any) => item.slug && list.push(item))
        dispatch(appSlice.actions.setAppList(list))
        dispatch(appSlice.actions.setLoading(false))
      })
      .catch((err: any) => {
        dispatch(appSlice.actions.setAppList([]))
        dispatch(appSlice.actions.setLoading(false))
      })
  }

export const connectApp =
  (
    slug: string,
    accountid: string,
    email: string,
    userid: string,
    appCredentials: {},
    loading = true
  ) =>
  (dispatch: AppDispatch) => {
    loading && dispatch(appSlice.actions.setLoading(true))
    dispatch(appSlice.actions.setWorkingApp(slug))
    return axios
      .post(
        `${config.apiUrl}/public/workflow-clients`,
        {
          email: isValidEmail(email) ? email : '',
          userId: isValidUserId(userid) ? userid : '',
          appSlug: slug,
          appCredentials,
        },
        {
          headers: {
            accountid: accountid,
          },
        }
      )
      .then((res: any) => {
        dispatch(appSlice.actions.setWorkingApp(''))
        dispatch(getAppList(accountid, email, userid, false))
        dispatch(appSlice.actions.setLoading(false))
      })
      .catch((err: any) => {
        dispatch(appSlice.actions.setWorkingApp(''))
        dispatch(appSlice.actions.setLoading(false))
        dispatch(getAppList(accountid, email, userid, false))
      })
  }

export const disconnectApp =
  (
    slug: string,
    accountid: string,
    email: string,
    userid: string,
    loading = true
  ) =>
  (dispatch: AppDispatch) => {
    loading && dispatch(appSlice.actions.setLoading(true))
    dispatch(appSlice.actions.setWorkingApp(slug))
    return axios
      .delete(`${config.apiUrl}/public/workflow-clients`, {
        headers: {
          accountid: accountid,
        },
        data: {
          email: isValidEmail(email) ? email : '',
          userId: isValidUserId(userid) ? userid : '',
          appSlug: slug,
        },
      })
      .then((res: any) => {
        if (res.data?.msg === 'success') {
          dispatch(getAppList(accountid, email, userid, false))
        }
        dispatch(appSlice.actions.setWorkingApp(''))
        dispatch(appSlice.actions.setLoading(false))
      })
      .catch((err: any) => {
        dispatch(appSlice.actions.setWorkingApp(''))
        dispatch(appSlice.actions.setLoading(false))
      })
  }

export const getAuthUrlMap =
  (accountid: string, loading = true) =>
  (dispatch: AppDispatch) => {
    loading && dispatch(appSlice.actions.setLoading(true))
    return axios
      .get(`${config.apiUrl}/public/apps-offered/auth-url`, {
        headers: {
          accountid: accountid,
        },
      })
      .then((res: any) => {
        if (res.data) {
          dispatch(appSlice.actions.setAuthUrlMap(res.data))
        }
        dispatch(appSlice.actions.setLoading(false))
      })
      .catch((err: any) => {
        dispatch(appSlice.actions.setLoading(false))
      })
  }
