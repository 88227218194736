import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import campminderLogo from '../../assets/icons/campminderIcon.png'
import campminderAdminLogin from '../../assets/icons/campminderAdminLogin.png'
import { config } from '../../config'
import { useDispatch } from 'react-redux'
import { connectApp } from '../../redux/services/app.service'
import { AppDispatch } from '../../redux'

const IntegrateWithCampminder = () => {
  const dispatch: AppDispatch = useDispatch()
  const location = useLocation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const urlSearchParams = new URLSearchParams(location.search.replace(config.appUrl, ''));
  const params = Object.fromEntries(urlSearchParams.entries());
  const { email, userid, accountid } = params

  useEffect(() => {
    if (errMsg.length > 0) {
      setTimeout(() => {
        setErrMsg('')
      }, 3000)
    }
  }, [errMsg])

  const onSubmitCred = async (e: any) => {
    e.preventDefault()
    if (username.length === 0 || password.length === 0) {
      return setErrMsg('Fields cannot be empty')
    }
    dispatch(connectApp('campminder', accountid, email, userid, { username, password }, false))
      .then(() => {
        window.close()
      })
  }

  return (
    <div className='campminderLoginPage'>
      <div className="container">
        <div className="mainArea">
          <div className="logoArea">
            <img className="logo" src={campminderLogo} alt="Campminder" />
          </div>
          <div className="verticalLine"></div>
          <div className="columnContainer">
            <form onSubmit={onSubmitCred}>
              <img className="adminIcon" src={campminderAdminLogin} alt="" />
              <div className="inputArea">
                <label htmlFor="username">username</label>
                <input type="text" id="username" autoFocus value={username} onChange={e => setUsername(e.target.value)} />
              </div>
              <div className="inputArea">
                <label htmlFor="password">password</label>
                <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
              </div>
              <button className="submit" type="submit" onClick={onSubmitCred}>Log In</button>
              <p className='errorMessage'>{errMsg}</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegrateWithCampminder