import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { apps } from "../../data/allApps";
import campminderIcon from "../../assets/icons/campminderIcon.svg";
import slackIcon from "../../assets/icons/slackIcon.svg";
import googleSheetsIcon from "../../assets/icons/googleSheetsIcon.svg";
import sharpspringIcon from "../../assets/icons/sharpspringIcon.svg";
import googleFormsIcon from "../../assets/icons/googleFormsIcon.svg";
import hubspotIcon from "../../assets/icons/hubspotIcon.svg";
import mailchimpIcon from "../../assets/icons/mailchimpIcon.svg";
import googleDriveIcon from "../../assets/icons/googleDriveIcon.svg";
import googleCalendarIcon from "../../assets/icons/googleCalendarIcon.svg";
import gmailIcon from "../../assets/icons/gmailIcon.svg";
import salesforceIcon from "../../assets/icons/salesforceIcon.svg";
import shopifyIcon from "../../assets/icons/shopifyIcon.svg";
import bigQueryIcon from "../../assets/icons/bigQueryIcon.svg";
import campBrainIcon from "../../assets/icons/campBrainIcon.svg";
import campSiteIcon from "../../assets/icons/campSiteIcon.svg";
import campSpotIcon from "../../assets/icons/campSpotIcon.svg";
import shipCalmIcon from "../../assets/icons/shipCalmIcon.svg";
import { config } from "../../config";
import { useSelector, useDispatch } from "react-redux";
import {
  disconnectApp,
  getAppList,
  getAuthUrlMap,
} from "../../redux/services/app.service";
import { AppDispatch } from "../../redux";
import Loader from "../Loader/Loader";
import warningWhite from "../../assets/icons/warningWhite.svg";
import integrateButtonIcon from "../../assets/icons/integrateButtonIcon.svg";

const Portal = () => {
  const location = useLocation();
  const allApps = apps.map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon,
    ][i];
    return r;
  });

  const { isLoading, appList, workingApp, authUrlMap } = useSelector(
    (state: any) => state.appInfo
  );
  const dispatch: AppDispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [userid, setUserId] = useState("");
  const [accountid, setAccountId] = useState("");
  const [error, setError] = useState("");
  const [isValid, setValid] = useState(false);

  const isValidEmail = (e: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(e.toLowerCase());
  };

  const isValidUserId = (u: string) =>
    u === "{userId}" || u === "" ? false : true;

  const checkValidityOfData = (
    _accountid: string,
    _email: string,
    _userid: string
  ) => {
    setAccountId(_accountid);
    if (
      (_email && isValidEmail(_email)) ||
      (_userid && isValidUserId(_userid))
    ) {
      setEmail(_email);
      setUserId(_userid);
    } else {
      setError(
        "Email or User ID required. Please contact support at support@champselect.ai for assistance."
      );
    }
    if (
      _accountid.length &&
      ((_email && isValidEmail(_email)) || (userid && isValidUserId(_userid)))
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    if (
      location.search.includes("accountid") &&
      (location.search.includes("userid") || location.search.includes("email"))
    ) {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.accountid && (params.email || params.userid)) {
        checkValidityOfData(
          params.accountid,
          params.email || "",
          params.userid || ""
        );
      }
    } else {
      setError(
        "One or more information missing. Please contact support for help."
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (isValid) {
      // dispatch(getAppDetails(app.slug, accountid, email, userid));
      dispatch(getAppList(accountid, email, userid));
      dispatch(getAuthUrlMap(accountid));
    }
  }, [isValid, accountid, email, userid, dispatch]);

  const integrateButton = (appType: string | undefined) => {
    switch (appType) {
      case "campminder":
        return integrateWithCampminder(appType);
      case "sharpspring":
        return integrateWithSharpspring(appType);
      case "gmail":
        return integrateWithGmail(appType);
      case "google_sheets":
        return integrateWithGoogleSheets(appType);
      default:
        break;
    }
  };

  const disconnectButton = (slug: string) => {
    dispatch(disconnectApp(slug, accountid, email, userid, false));
  };

  const integrateWithCampminder = (slug: string) => {
    const integrationWindow = window.open(
      `${config.integrateUrl}?app=${slug}&accountId=${accountid}&email=${email}&userId=${userid}`,
      "",
      "width=900, height=700, top=100, left=100"
    );
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval);
        dispatch(getAppList(accountid, email, userid, false));
      }
    }, 1000);
  };

  const integrateWithSharpspring = (slug: string) => {
    const integrationWindow = window.open(
      `${config.integrateUrl}?app=${slug}&accountId=${accountid}&email=${email}&userId=${userid}`,
      "",
      "width=900, height=700, top=100, left=100"
    );
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval);
        dispatch(getAppList(accountid, email, userid, false));
      }
    }, 1000);
  };

  const integrateWithGmail = (slug: string) => {
    const integrationWindow = window.open(
      `${authUrlMap[slug]}&state=${JSON.stringify({
        accountId: accountid,
        app: slug,
        email,
        userId: userid,
      })}`,
      "",
      "width=900, height=700, top=100, left=100"
    );
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval);
        dispatch(getAppList(accountid, email, userid, false));
      }
    }, 1000);
  };

  const integrateWithGoogleSheets = (slug: string) => {
    const integrationWindow = window.open(
      `${authUrlMap[slug]}&state=${JSON.stringify({
        accountId: accountid,
        app: slug,
        email,
        userId: userid,
      })}`,
      "",
      "width=900, height=700, top=100, left=100"
    );
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval);
        dispatch(getAppList(accountid, email, userid, false));
      }
    }, 1000);
  };

  return (
    <>
      <Navbar />
      <div className="linkPortal">
        {error && (
          <div className="absoluteContainer">
            <div className="errorContainer">
              <img src={warningWhite} className="icon" alt="Error" /> {error}
            </div>
          </div>
        )}
        <div className="connectAppsCard">
          <div className="cardTitle">Connect Apps</div>
          <p className="cardSubtitle">
            Powered by <span>ChampSelect.ai</span>
          </p>
          <div className="scrollableContainer">
            {isLoading ? (
              <div className="loadingContainer">
                <Loader color="#81B8F9" />
              </div>
            ) : (
              <div className="buttonsContainer">
                {appList.length > 0
                  ? appList.map((app: any) => (
                      <div className="button" key={app?.slug}>
                        <div className="left">
                          <img
                            src={
                              allApps.find((a: any) => a.slug === app.slug)
                                ?.icon
                            }
                            className="icon"
                            alt={app?.slug}
                            style={{
                              width: `${
                                app?.slug === "shipcalm" ? "80px" : "40px"
                              }`,
                            }}
                          />
                          <div className="appName">
                            {
                              allApps.find((a: any) => a.slug === app.slug)
                                ?.name
                            }
                          </div>
                        </div>
                        {app?.connected ? (
                          <button
                            className={`buttonPreview disconnectButton ${
                              workingApp === app?.slug && "loading"
                            }`}
                            onClick={() => disconnectButton(app?.slug)}
                            disabled={workingApp === app?.slug}
                          >
                            Disconnect
                          </button>
                        ) : (
                          <button
                            className={`buttonPreview ${
                              workingApp === app?.slug && "loading"
                            }`}
                            onClick={() => integrateButton(app?.slug!)}
                            disabled={workingApp === app?.slug}
                          >
                            Integrate
                            <img
                              src={integrateButtonIcon}
                              className="buttonIcon"
                              alt=""
                            />
                          </button>
                        )}
                      </div>
                    ))
                  : null}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Portal;
