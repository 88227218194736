const Footer = () => {

  return (
    <div className='footer'>
      <p className='footerText'>Copyright © ChampSelect.ai 2022. All Rights Reserved.</p>
    </div>
  )
}

export default Footer
