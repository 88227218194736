import { Route, Routes } from "react-router-dom";
// Components
import Home from "./Component/Home/Home";
// import EmptyWidget from './Component/EmptyWidget/EmptyWidget';
import IntegrateWithCampminder from "./Component/IntegrateWithCampminder/IntegrateWithCampminder";
import IntegrateWithSharpspring from "./Component/IntegrateWithSharpspring/IntegrateWithSharpspring";
import Portal from "./Component/Portal/Portal";

const App = () => {
  return (
    <Routes>
      <Route path="/campminder" element={<IntegrateWithCampminder />} />
      <Route path="/sharpspring" element={<IntegrateWithSharpspring />} />
      <Route path="/portal" element={<Portal />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default App;
