import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import Widget from '../../ChampselectInlineWidget/ChampselectInlineWidget'

const Home = () => {
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [userid, setUserId] = useState('')
  const [accountid, setAccountId] = useState('')

  useEffect(() => {
    if (location.search.includes('accountid') && (location.search.includes('userid') || location.search.includes('email'))) {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      params.accountid && setAccountId(params.accountid)
      params.email && setEmail(params.email)
      params.userid && setUserId(params.userid)
    }
  }, [location.search])

  return <Widget
    accountid={accountid}
    email={email}
    userid={userid}
  />
}

export default Home