export const apps = [
  {
    name: 'Campminder',
    icon: 'campminder.svg',
    isAdded: false,
    primaryColor: '#3B8383',
    slug: 'campminder',
  },
  {
    name: 'Gmail',
    icon: 'gmail.svg',
    isAdded: false,
    primaryColor: '#EA4335',
    slug: 'gmail',
  },
  {
    name: 'Google Sheets',
    icon: 'googleSheet.svg',
    isAdded: false,
    primaryColor: '#23A566',
    slug: 'google_sheets',
  },
  {
    name: 'SharpSpring',
    icon: 'sharpspring.png',
    isAdded: false,
    primaryColor: '#20A464',
    slug: 'sharpspring',
  },
  {
    name: 'Slack',
    icon: 'slack.svg',
    isAdded: false,
    primaryColor: '#4a154b',
    slug: 'slack',
  },
  {
    name: 'Google Forms',
    icon: 'googleForms.svg',
    isAdded: false,
    primaryColor: '#754cbd',
    slug: 'google_forms',
  },
  {
    name: 'Hubspot',
    icon: 'hubspot.svg',
    isAdded: false,
    primaryColor: '#FF7B57',
    slug: 'hubspot',
  },
  {
    name: 'Mailchimp',
    icon: 'mailchimp.svg',
    isAdded: false,
    primaryColor: '#ffd14f',
    slug: 'mailchimp',
  },
  {
    name: 'Google Drive',
    icon: 'googleDrive.svg',
    isAdded: false,
    primaryColor: '#00ae3c',
    slug: 'google_drive',
  },
  {
    name: 'Google Calendar',
    icon: 'googleCalendar.svg',
    isAdded: false,
    primaryColor: '#4285F4',
    slug: 'google_calendar',
  },
  {
    name: 'Salesforce',
    icon: 'salesforce.svg',
    isAdded: false,
    primaryColor: '#00A1E0',
    slug: 'salesforce',
  },
  {
    name: 'Shopify',
    icon: 'shopify.svg',
    isAdded: false,
    primaryColor: '#95BF46',
    slug: 'shopify',
  },
  {
    name: 'BigQuery',
    icon: 'bigquery.svg',
    isAdded: false,
    primaryColor: '#4386fa',
    slug: 'bigquery',
  },
  {
    name: 'CampBrain',
    icon: 'campbrain.svg',
    isAdded: false,
    primaryColor: '#547e2d',
    slug: 'campbrain',
  },
  {
    name: 'CampSite',
    icon: 'campsite.svg',
    isAdded: false,
    primaryColor: '#597c54',
    slug: 'campsite',
  },
  {
    name: 'CampSpot',
    icon: 'campspot.svg',
    isAdded: false,
    primaryColor: '#2ecc71',
    slug: 'campspot',
  },
  {
    name: 'ShipCalm',
    icon: 'shipcalm.svg',
    isAdded: false,
    primaryColor: '#1caae2',
    slug: 'shipcalm',
  },
]
