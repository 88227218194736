import { Link } from 'react-router-dom'
import champSelectLogo from '../../assets/icons/champselect-logo.svg'

const Navbar = () => {

  return (
    <div className='navbar'>
      <div className='logoArea'>
        <div className='logoContainer'>
          <img src={champSelectLogo} alt='ChampSelect logo' className='logo' />
          <span className='title'>ChampSelect.ai</span>
        </div>
        {/* {accountName && (
          <>
            <div className='verticalSeparator' />
            <div className='org'>{accountName}</div>
          </>
        )} */}
      </div>
    </div>
  )
}

export default Navbar
