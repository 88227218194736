import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  appList: [],
  workingApp: '',
  authUrlMap: {},
}

const setLoading = (state = initialState, action: any) => {
  return {
    ...state,
    isLoading: action.payload,
  }
}

const setAppList = (state = initialState, action: any) => {
  return {
    ...state,
    appList: action.payload,
  }
}

const setWorkingApp = (state = initialState, action: any) => {
  return {
    ...state,
    workingApp: action.payload,
  }
}

const setAuthUrlMap = (state = initialState, action: any) => {
  return {
    ...state,
    authUrlMap: action.payload,
  }
}

const appSlice: any = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading,
    setAppList,
    setWorkingApp,
    setAuthUrlMap,
  },
})

export default appSlice
