import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import sharpspringLogo from '../../assets/icons/sharpspring.svg'
import { config } from '../../config'
import { useDispatch } from 'react-redux'
import { connectApp } from '../../redux/services/app.service'
import { AppDispatch } from '../../redux'

const IntegrateWithSharpspring = () => {
  const dispatch: AppDispatch = useDispatch()
  const location = useLocation()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')

  const urlSearchParams = new URLSearchParams(location.search.replace(config.appUrl, ''));
  const params = Object.fromEntries(urlSearchParams.entries());
  const { email, userid, accountid } = params

  useEffect(() => {
    if (errMsg.length > 0) {
      setTimeout(() => {
        setErrMsg('')
      }, 3000)
    }
  }, [errMsg])

  const onSubmitCred = async (e: any) => {
    e.preventDefault()
    if (username.length === 0 || password.length === 0) {
      return setErrMsg('Fields cannot be empty')
    }
    dispatch(connectApp('sharpspring', accountid, email, userid, { username, password }, false))
      .then(() => {
        window.close()
      })
  }

  return (
    <div className='sharpspringLoginPage'>
      <div className="container">
        <div className="mainArea">
          <form onSubmit={onSubmitCred}>
            <div className="logoArea">
              <img className="logo" src={sharpspringLogo} alt="Sharpspring" />
            </div>
            <div className="horizontalLine"></div>
            <p className='welcomeText'>Welcome Back!</p>
            <div className="inputArea">
              <input type="text" id="username" placeholder='Username' autoFocus value={username} onChange={e => setUsername(e.target.value)} />
            </div>
            <div className="inputArea">
              <input type="password" id="password" placeholder='Password' value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <button className="submit" type="submit" onClick={onSubmitCred}>Log In</button>
            <p className='errorMessage'>{errMsg}</p>
          </form>
        </div>
      </div>
    </div>
  )
}

export default IntegrateWithSharpspring