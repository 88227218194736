import React, { useEffect, useState } from "react"
import "./ChampselectInlineWidget.scss"
import { apps } from "../data/allApps"
import campminderIcon from "../assets/icons/campminderIcon.svg"
import slackIcon from "../assets/icons/slackIcon.svg"
import googleSheetsIcon from "../assets/icons/googleSheetsIcon.svg"
import sharpspringIcon from "../assets/icons/sharpspringIcon.svg"
import googleFormsIcon from "../assets/icons/googleFormsIcon.svg"
import hubspotIcon from "../assets/icons/hubspotIcon.svg"
import mailchimpIcon from "../assets/icons/mailchimpIcon.svg"
import googleDriveIcon from "../assets/icons/googleDriveIcon.svg"
import googleCalendarIcon from "../assets/icons/googleCalendarIcon.svg"
import gmailIcon from "../assets/icons/gmailIcon.svg"
import salesforceIcon from "../assets/icons/salesforceIcon.svg"
import shopifyIcon from "../assets/icons/shopifyIcon.svg"
import bigQueryIcon from "../assets/icons/bigQueryIcon.svg"
import campBrainIcon from "../assets/icons/campBrainIcon.svg"
import campSiteIcon from "../assets/icons/campSiteIcon.svg"
import campSpotIcon from "../assets/icons/campSpotIcon.svg"
import shipCalmIcon from "../assets/icons/shipCalmIcon.svg"
import { useSelector, useDispatch } from "react-redux"
import {
  disconnectApp,
  getAuthUrlMap,
  getAppList,
} from "../redux/services/app.service"
import { AppDispatch } from "../redux"
import warningRed from "../assets/icons/warningRed.svg"
import { config } from "../config"
import Loader from "../Component/Loader/Loader"
import integrateButtonIcon from "../assets/icons/integrateButtonIcon.svg"

export interface AppProps {
  accountid: string
  email: string
  userid: string
}

const Widget: React.FC<AppProps> = ({ accountid, email, userid }) => {

  const allApps = apps.map((r, i) => {
    r.icon = [
      campminderIcon,
      gmailIcon,
      googleSheetsIcon,
      sharpspringIcon,
      slackIcon,
      googleFormsIcon,
      hubspotIcon,
      mailchimpIcon,
      googleDriveIcon,
      googleCalendarIcon,
      salesforceIcon,
      shopifyIcon,
      bigQueryIcon,
      campBrainIcon,
      campSiteIcon,
      campSpotIcon,
      shipCalmIcon,
    ][i]
    return r
  })

  const { isLoading, appList, workingApp, authUrlMap } = useSelector(
    (state: any) => state.appInfo
  )
  const dispatch: AppDispatch = useDispatch()
  const [error, setError] = useState("")
  const [isValid, setValid] = useState(false)

  const isValidEmail = (e: string) => {
    const regex =
      /^(([^<>()[\]\\., :\s@"]+(\.[^<>()[\]\\., :\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(e.toLowerCase())
  }

  const isValidUserId = (u: string) =>
    u === "{userId}" || u === "" ? false : true

  const checkValidityOfData = (
    _accountid: string,
    _email: string,
    _userid: string
  ) => {
    if (
      !(
        (_email.length && isValidEmail(_email)) ||
        (_userid && isValidUserId(_userid))
      )
    ) {
      setError(
        "Email or User ID required. Please contact support at support@champselect.ai for assistance."
      )
    }
    if (
      _accountid.length &&
      ((_email && isValidEmail(_email)) || (userid && isValidUserId(_userid)))
    ) {
      setValid(true)
      setError("")
    } else {
      setValid(false)
    }
  }

  useEffect(() => {
    if (accountid.length) {
      checkValidityOfData(accountid, email || "", userid || "")
    } else {
      setError(
        "One or more information missing. Please contact support for help."
      )
    }
  }, [accountid, userid, email])

  useEffect(() => {
    if (isValid) {
      dispatch(getAuthUrlMap(accountid))
      dispatch(getAppList(accountid, email, userid))
    }
  }, [isValid, accountid, email, userid])

  const integrateButton = (appType: string | undefined) => {
    switch (appType) {
      case "campminder":
        return integrateWithCampminder(appType)
      case "sharpspring":
        return integrateWithSharpspring(appType)
      case "gmail":
        return integrateWithGmail(appType)
      default:
        break
    }
  }

  const disconnectButton = (slug: string) => {
    dispatch(disconnectApp(slug, accountid, email, userid, false))
  }

  const integrateWithCampminder = (slug: string) => {
    const integrationWindow = window.open(
      `${config.integrateUrl}?app=${slug}&accountId=${accountid}&email=${email}&userId=${userid}`,
      "",
      "width=900, height=700, top=100, left=100"
    )
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval)
        dispatch(getAppList(accountid, email, userid, false))
      }
    }, 1000)
  }

  const integrateWithSharpspring = (slug: string) => {
    const integrationWindow = window.open(
      `${config.integrateUrl}?app=${slug}&accountId=${accountid}&email=${email}&userId=${userid}`,
      "",
      "width=900, height=700, top=100, left=100"
    )
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval)
        dispatch(getAppList(accountid, email, userid, false))
      }
    }, 1000)
  }

  const integrateWithGmail = (slug: string) => {
    const integrationWindow = window.open(
      `${authUrlMap[slug]}&state=${JSON.stringify({
        accountId: accountid,
        app: slug,
        email,
        userId: userid,
      })}`,
      "",
      "width=900, height=700, top=100, left=100"
    )
    const interval = setInterval(() => {
      if (integrationWindow?.closed) {
        clearInterval(interval)
        dispatch(getAppList(accountid, email, userid, false))
      }
    }, 1000)
  }

  return (
    <div className="widget">
      <div className='connectAppsCard'>
        <div className='cardTitle'>Connect Apps</div>
        <p className='cardSubtitle'>Powered by <span>ChampSelect.ai</span></p>
        <div className="scrollableContainer">
          {isLoading ? <div className="loadingContainer"><Loader color='#81B8F9' /></div>
            : error ? (
              <div className="errorContainer">
                <div className="errorCard">
                  <img src={warningRed} className="icon" alt="Error" />
                  {error}
                </div>
              </div>
            ) : (
              <div className="buttonsContainer">
                {appList.length > 0 ? (
                  appList.map((app: any) => (
                    <div className="button" key={app?.slug} >
                      <div className="left">
                        <img
                          src={allApps.find((a: any) => a.slug === app.slug)?.icon}
                          className='icon'
                          alt={app?.slug}
                          style={{ width: `${app?.slug === 'shipcalm' ? '80px' : '40px'}` }}
                        />
                        <div className="appName">{allApps.find((a: any) => a.slug === app.slug)?.name}</div>
                      </div>
                      {app?.connected ? (
                        <button
                          className={`buttonPreview disconnectButton ${workingApp === app?.slug && 'loading'}`}
                          onClick={() => disconnectButton(app?.slug)}
                          disabled={workingApp === app?.slug}
                        >
                          {isLoading ? <Loader color='#ffffff' /> : 'Disconnect'}
                        </button>
                      ) : (
                        <button
                          className={`buttonPreview ${workingApp === app?.slug && 'loading'}`}
                          onClick={() => integrateButton(app?.slug!)}
                          disabled={workingApp === app?.slug}
                        >
                          {isLoading ? <Loader color='#ffffff' /> : 'Integrate'}
                          <img src={integrateButtonIcon} className='buttonIcon' alt="" />
                        </button>
                      )}
                    </div>
                  ))
                ) : null}
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default Widget 
